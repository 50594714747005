









import { Component, Vue } from "vue-property-decorator";

@Component({
  metaInfo: {
    title: "Home",
  },
})
export default class Home extends Vue {}
