
















































import { Component, Vue } from "vue-property-decorator";
import GitHubVersionLink from "@/components/GitHubVersionLink.vue";
import LoadingIcon from "@/components/LoadingIcon.vue";
import { getVersion } from "../common/version";

/**
 * The link to the GitHub repository.
 */
const GIT_ROOT = "https://github.com/johannes-huther/www.johannes.huther.link";

/**
 * The main {@link Vue}-View that contains the App.
 */
@Component({
  components: { LoadingIcon, GitHubVersionLink },
  metaInfo: {
    titleTemplate: "%s - Johannes Huther",
  },
  data() {
    return { GIT_ROOT };
  },
})
export default class App extends Vue {
  /**
   * A boolean indicating whether all API calls have been finished (`false`) or if they are still loading.
   */
  loading = false;

  /**
   * The current version of this software. Either a release version (e.g. `v0.1.0`) or a short commit SHA.
   */
  version = getVersion();
}
