








import { Component, Vue } from "vue-property-decorator";

/**
 * A {@link Vue}-component with a rotating icon.
 */
@Component({})
export default class LoadingIcon extends Vue {}
